import React from 'react'
import Password from './components/Password'

function App() {
  return (
    <Password />
  );
}

export default App;
