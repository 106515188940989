import React from 'react';
import clsx from 'clsx';
import propTypes from 'prop-types';

import styles from './Notification.module.scss';

const Notification = (props) => {
  const {
    show, children, warning, success, failure,
  } = props;
  return (
    <div className={clsx({
      [styles.notification]: true,
      [styles.showNotification]: show,
      [styles.warning]: warning,
      [styles.success]: success,
      [styles.failure]: failure,
    })}
    >
      {children}
    </div>
  );
};

Notification.propTypes = {
  show: propTypes.bool.isRequired,
  warning: propTypes.bool.isRequired,
  success: propTypes.bool.isRequired,
  failure: propTypes.bool.isRequired,
  children: propTypes.string.isRequired,
};

export default Notification;
