/* eslint-disable consistent-return */
import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { CloseCircleFilled } from '@ant-design/icons';
import clsx from 'clsx';
import axios from 'axios';
import validator from 'validator';
import { firebase } from '../firebase/config';
import functionURL from '../helpers/urlHelper';
import Notification from './Notification';

import LoginLogo from '../assets/login-logo.svg';
import LockIcon from '../assets/lock.svg';
import MailIcon from '../assets/mail.svg';
import Loader from './Loader';

import styles from './Password.module.scss';
import validatePassword from '../helpers/passwordHelper';

const BASE_URL = functionURL();

const Password = (props) => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [resendError, setResendError] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);

  // INVALID CLASSES
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState(null);
  const [emailInvalid, setEmailInvalid] = useState(null);
  const [formValid, setFormValid] = useState(null);

  // NOTIFICATION
  const [notification, setNotification] = useState(false);
  const [notificationText, setNotificationText] = useState('Hello');
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [failure, setFailure] = useState(false);

  const validationRules = validatePassword.validate(password, { list: true });

  const getParameterByName = (name) => {
    const params = new URLSearchParams(`${document.location.search}`.slice(1));
    return params.get(name);
  };

  const handlePassword = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  const handleConfirmPassword = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
  };

  const handleShowPassword = () => {
    if (passwordType === 'text') {
      return setPasswordType('password');
    }
    setPasswordType('text');
  };

  const handleShowConfirmPassword = () => {
    if (confirmPasswordType === 'text') {
      return setConfirmPasswordType('password');
    }
    setConfirmPasswordType('text');
  };

  const onEmailChange = (e) => {
    const { value } = e.target;
    if (!validator.isEmail(value)) {
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }
    setEmail(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setIsSuccessful(false);
    if (!formValid) {
      setNotification(true);
      setWarning(true);
      setNotificationText('Your passwords do not match. Please try again');
      setTimeout(() => {
        setWarning(false);
        setNotification(false);
        setIsLoading(false);
      }, 2000);
    }

    if (formValid && validationRules.length === 0) {
      setIsLoading(true);
      const mode = getParameterByName('mode');
      const actionCode = getParameterByName('oobCode');
      const continueUrl = getParameterByName('continueUrl');

      console.log(continueUrl);

      const auth = firebase.auth();

      try {
        if (mode === 'resetPassword') {
          const emailAddress = await auth.verifyPasswordResetCode(actionCode);
          await auth.confirmPasswordReset(actionCode, password);

          const response = await axios({
            url: `${BASE_URL}/updateStatus`,
            method: 'POST',
            data: { emailAddress },
          });

          console.log(response.data);

          setIsLoading(false);
          setIsSuccessful(true);

          setTimeout(() => {
            window.location.replace(continueUrl);
          }, 5000);
        } else if (mode === 'verifyEmail') {
          await auth
            .applyActionCode(actionCode)
            .then((response) => {
              console.log(`response`, response);
              setIsLoading(false);
              setIsSuccessful(true);
              setTimeout(() => {
                window.location.replace(continueUrl);
              }, 5000);
            })
            .catch((err) => {
              console.log(`err`, err);
            });
        } else {
          setNotification(true);
          setWarning(true);
          setNotificationText('You are not authorized to perform this action');
          setTimeout(() => {
            setWarning(false);
            setNotification(false);
            setIsLoading(false);
          }, 2000);
        }
      } catch (err) {
        console.log(err);
        if (err.code === 'auth/invalid-action-code' || err.code === 'auth/expired-action-code') {
          setNotification(true);
          setWarning(true);
          setNotificationText('This link has expired. Kindly reset your password');
          setTimeout(() => {
            setWarning(false);
            setNotification(false);
            setIsLoading(false);
            setIsExpired(true);
          }, 2000);
          return;
        }
        setNotification(true);
        setWarning(true);
        setNotificationText('An error occurred');
        setTimeout(() => {
          setWarning(false);
          setNotification(false);
          setIsLoading(false);
          history.replace('/');
        }, 2000);
      }
    }
  };

  /** HANDLE RESEND PASSWORD LINK */
  const handleResendPasswordLink = async () => {
    if (validator.isEmail(email)) {
      setIsLoading(true);
      try {
        const response = await axios.post(`${BASE_URL}/resendPasswordLink`, {
          email,
        });

        if (response.status !== 200) {
          setIsLoading(false);
          setResendError(true);
          setResendSuccess(false);
          setNotification(true);
          setSuccess(true);
          setNotificationText('Email address provided is not recognized');
          setTimeout(() => {
            setSuccess(false);
            setNotification(false);
          }, 3000);
          return;
        }

        setIsLoading(false);
        setResendError(false);
        setResendSuccess(true);
        setNotification(true);
        setSuccess(true);
        setNotificationText(`Password link has been sent to ${email}`);
        setTimeout(() => {
          setSuccess(false);
          setNotification(false);
        }, 3000);
      } catch (e) {
        setIsLoading(false);
        setResendError(true);
        setResendSuccess(false);
        setNotification(true);
        setFailure(true);
        setNotificationText('Password link could not be sent. Try again');
        setTimeout(() => {
          setWarning(false);
          setNotification(false);
        }, 2000);
      }
    } else {
      // Invalid Email
      setNotification(true);
      setWarning(true);
      setNotificationText('Please enter a valid email and try again');
      setTimeout(() => {
        setWarning(false);
        setNotification(false);
      }, 2000);
    }
  };

  //SHOW ERROR MESSAGE
  const handleInput = () => {
    if (validationRules.length > 0) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
  };

  const handleFocus = () => {
    if (validationRules.length > 0) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
  };

  const handleBlur = () => {
    setPasswordInvalid(false);
  };

  //LISTEN FOR ERROR
  useEffect(() => {
    const mode = getParameterByName('mode');
    const actionCode = getParameterByName('oobCode');
    const continueUrl = getParameterByName('continueUrl');

    if (mode === null || actionCode === null || continueUrl === null) {
      if (process.env.NODE_ENV === 'development') {
        return;
      }
      window.location.replace(`https://www.gatepass.io`);
    }
  }, []);

  // LISTEN FOR LOGIN ERRORS
  useEffect(() => {
    if (password !== confirmPassword) {
      setPasswordInvalid(true);
      setConfirmPasswordInvalid(true);
      setFormValid(false);
    } else {
      setPasswordInvalid(false);
      setConfirmPasswordInvalid(false);
      setFormValid(true);
    }
  }, [password, confirmPassword]);

  const renderPage = () => {
    if (isLoading) {
      return <Loader small />;
    }

    if (!isLoading && isSuccessful) {
      return (
        <Fragment>
          <p className={styles.successLargeText}>Password Updated</p>
          <p className={styles.successMiniText}>You will be redirected to login page shortly</p>
        </Fragment>
      );
    }

    if (!isLoading && !resendError && resendSuccess) {
      return (
        <Fragment>
          <p className={styles.successMiniText}>A password link has been sent to</p>
          <p className={styles.successMiniText}>{`${email}`}</p>
        </Fragment>
      );
    }

    if (!isLoading && isExpired) {
      return (
        <div className={styles.verificationErrorForm}>
          <p className={styles.successLargeText}>Resend Password Link</p>
          <div
            className={clsx({
              [styles.resendVerificationContainer]: true,
              [styles.invalidInput]: emailInvalid,
            })}
          >
            <img className={styles.inputIcon} src={MailIcon} alt="mail_icon" />
            <input
              className={styles.loginInput}
              type="email"
              placeholder="Enter Email Address"
              value={email}
              onChange={onEmailChange}
              required
            />
          </div>
          <button onClick={handleResendPasswordLink} type="button" className={styles.resendButton}>
            Resend Password link
          </button>
        </div>
      );
    }

    return (
      <Fragment>
        <p className={styles.formHeaderText}>Welcome</p>
        <p className={styles.formSubHeaderText}>Please create a password</p>
        {passwordInvalid ? (
          <div className={styles.validationMessage}>
            {validationRules.includes('min') ? (
              <span>
                minimum 7 characters
                <CloseCircleFilled style={{ color: 'red', fontSize: '13px', marginLeft: '5px' }} />
              </span>
            ) : null}
            {validationRules.includes('lowercase') ? (
              <span>
                contains lowercase
                <CloseCircleFilled style={{ color: 'red', fontSize: '13px', marginLeft: '5px' }} />
              </span>
            ) : null}
            {validationRules.includes('uppercase') ? (
              <span>
                contains uppercase
                <CloseCircleFilled style={{ color: 'red', fontSize: '13px', marginLeft: '5px' }} />
              </span>
            ) : null}
            {validationRules.includes('digits') ? (
              <span>
                contains digits
                <CloseCircleFilled style={{ color: 'red', fontSize: '13px', marginLeft: '5px' }} />
              </span>
            ) : null}
            {validationRules.includes('max') ? (
              <span>
                maximum 42 characters
                <CloseCircleFilled style={{ color: 'red', fontSize: '13px', marginLeft: '5px' }} />
              </span>
            ) : null}
          </div>
        ) : null}
        <div className={styles.loginInputContainer}>
          <img className={styles.inputIcon} src={LockIcon} alt="lock_icon" />
          <input
            className={styles.loginInput}
            type={passwordType}
            placeholder="Enter Password"
            value={password}
            onChange={handlePassword}
            onInput={handleInput}
            onBlur={handleBlur}
            onFocus={handleFocus}
            required
          />
          <p onClick={handleShowPassword} className={styles.showPassword}>
            {passwordType === 'text' ? 'Hide' : 'Show'}
          </p>
        </div>
        <div
          className={clsx({
            [styles.loginInputContainer]: true,
            [styles.invalidInput]: confirmPasswordInvalid,
          })}
        >
          <img className={styles.inputIcon} src={LockIcon} alt="lock_icon" />
          <input
            className={styles.loginInput}
            type={confirmPasswordType}
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPassword}
            required
            minLength={6}
          />
          <p onClick={handleShowConfirmPassword} className={styles.showPassword}>
            {confirmPasswordType === 'text' ? 'Hide' : 'Show'}
          </p>
        </div>
        <button
          disabled={isLoading || validationRules.length > 0}
          className={styles.loginButton}
          type="submit"
        >
          {isLoading ? 'Creating' : 'Create Password'}
        </button>
      </Fragment>
    );
  };

  return (
    <div className={styles.loginContainer}>
      <Notification show={notification} success={success} failure={failure} warning={warning}>
        {notificationText}
      </Notification>
      <div className={styles.logoContainer}>
        <img className={styles.loginLogo} src={LoginLogo} alt="gatepass_logo" />
        <p className={styles.loginLogoText}>GatePass</p>
      </div>
      <form onSubmit={handleSubmit} className={styles.loginForm}>
        {renderPage()}
      </form>
    </div>
  );
};

export default Password;
