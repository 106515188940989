const passwordValidator = require('password-validator');
const schema = new passwordValidator();
const upperPass = 'Pass';
const lowerPass = 'pass'
const blacklist = [
    `${upperPass}word`, 
    `${upperPass}w0rd`, 
    `${lowerPass}word`, 
    `${lowerPass}w0rd`,
    `${upperPass}word1234567`, 
    `${upperPass}w0rd1234567`, 
    `${lowerPass}word1234567`, 
    `${lowerPass}w0rd1234567`,
]
const validatePassword = schema
.is().min(7)
.is().max(42)
.has().lowercase()
.has().uppercase()
.has().digits()
.has().not().spaces()
.is().not().oneOf(blacklist);

export default validatePassword ;