import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyC9zbuOoPvhV9YgK5rV5kFkaIc1xjltODs",
  authDomain: "gatepass-228e2.firebaseapp.com",
  databaseURL: "https://gatepass-228e2.firebaseio.com",
  projectId: "gatepass-228e2",
  storageBucket: "gatepass-228e2.appspot.com",
  messagingSenderId: "475186389568",
  appId: "1:475186389568:web:89683aebd868afdcc2f5a5",
  measurementId: "G-ZK3QZ877WR",
};

// Initialize Firebase App with Configurations
firebase.initializeApp(config);

// Setup Firestore
const database = firebase.firestore();
const storage = firebase.storage();

export { firebase, storage, database as default };
